import React, { useState } from 'react';
import heydayLoader from '../services/heyday/heydayloader';
import { Box, Flex, Container, SimpleGrid, Text, Button, Link,
  NumberInput, NumberInputField, NumberInputStepper,
  NumberIncrementStepper, NumberDecrementStepper,
  Select, Stack, useToast, Switch, FormControl, FormLabel, Input,
  Heading, Stat, StatLabel, StatNumber, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton } from '@chakra-ui/react';
import { FaExternalLinkAlt } from 'react-icons/fa';

const NGROK_ENDPOINT = process.env.REACT_APP_PAYMENT_ENDPOINT || "https://give-more-backend-739223026498.us-central1.run.app/billing/create-payment-session"; // Replace with your ngrok URL

const treeOptions = [50, 100, 500, 1000, 5000, 10000];

const TreeOption = ({ trees, selected, onClick }) => (
  <Button
    variant="outline"
    bg={selected ? "green.500" : "white"}
    color={selected ? "white" : "inherit"}
    borderColor={selected ? "green.500" : "gray.200"}
    _hover={{
      bg: selected ? "green.600" : "gray.50",
      borderColor: selected ? "green.600" : "gray.300"
    }}
    onClick={onClick}
    p={4}
    width="100%"
  >
    <Text>{trees} trees</Text>
  </Button>
);

const CustomTreeInput = ({ value, onChange, selected, onClick }) => (
  <Box
    width="100%"
    borderWidth="1px"
    borderColor={selected ? "green.500" : "gray.200"}
    borderRadius="md"
    overflow="hidden"
    bg="white"
  >
    <Text 
      fontSize="sm" 
      fontWeight="medium" 
      p={3} 
      pb={0}
      color="gray.700"
    >
      Custom amount
    </Text>
    <Input
      value={value}
      onChange={(e) => onChange(e.target.value)}
      placeholder="Enter amount"
      border="none"
      p={3}
      pt={1}
      _focus={{
        outline: "none",
        boxShadow: "none"
      }}
      type="number"
      min={1}
      max={100000}
    />
  </Box>
);

export default function GiveMoreView({id}) {
    const [data, setData] = React.useState(null)
    const [state, setState] = React.useState(null)
    const [gridColumns, setGridColumns] = React.useState(1)
    const [selectedTrees, setSelectedTrees] = useState(50);
    const [isRecurring, setIsRecurring] = useState(false);
    const [customTrees, setCustomTrees] = useState('');
    const [isCustom, setIsCustom] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const toast = useToast();

    React.useEffect(() => {
       var d =  heydayLoader(id)
       setData(d)

       // Check for a query parameter to show the modal
       const urlParams = new URLSearchParams(window.location.search);
       if (urlParams.get('paymentSuccess')) {
           setIsModalOpen(true); // Open modal if paymentSuccess is present
       }

       // Clear the paymentSuccess query parameter
       urlParams.delete('paymentSuccess');
       window.history.replaceState({}, document.title, window.location.pathname + '?' + urlParams.toString());
    }, [id])

    // Function to close the modal
    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    // Add new handler for Stripe checkout
    const handleContribute = async () => {
        try {
            const returnUrl = window.location.href; // Get the current URL as the return URL
            const queryParams = new URLSearchParams({
                monthly: isRecurring,
                totalTrees: selectedTrees,
                returnURL: returnUrl // Add returnUrl to the query parameters
            }).toString();

            const response = await fetch(`${NGROK_ENDPOINT}?${queryParams}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                throw new Error('Payment request failed');
            }

            const data = await response.text();
            console.log('Payment request successful:', data);
            
            // Redirect to the URL returned from the API
            window.location.href = data // Assuming the API returns a "url" field
            
            toast({
                title: 'Success',
                description: 'Redirecting to payment...',
                status: 'success',
                duration: 5000,
            });

        } catch (error) {
            console.error('Payment error:', error);
            toast({
                title: 'Error',
                description: 'Unable to process payment request',
                status: 'error',
                duration: 5000,
            });
        }
    };

    const handleTreeSelection = (trees) => {
        setSelectedTrees(trees);
        setIsCustom(false);
    };

    const handleCustomSelection = () => {
        setIsCustom(true);
        if (customTrees) {
            setSelectedTrees(Number(customTrees));
        }
    };

    const handleCustomTreesChange = (value) => {
        setCustomTrees(value);
        setSelectedTrees(Number(value));
    };

    if (!data) {
        return (
            <div>
                <h1>Loading...</h1>
            </div>
        )
    }
//https://www.madebyheyday.com/ 
    const {logo, description,total_trees_planted_copy, total_trees, did_you_know_copy,  title, banner_background, second_title, images, second_description} = data
    
    return (
      <Container minWidth="100%" p="0" bgColor="#EDF0F4">
        {/* Modal for thank you message */}
        <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Thank You!</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text>Your contribution is greatly appreciated!</Text>
              <Text mt={4}>
                Your support helps plant trees, which in turn:
              </Text>
              <Stack spacing={2} mt={2}>
                <Text>🌱 Sequesters carbon dioxide, helping to combat climate change.</Text>
                <Text>🐦 Provides habitat for wildlife, promoting biodiversity.</Text>
                <Text>💧 Filters water, improving local water quality.</Text>
                <Text>🌍 Supports local communities through sustainable practices.</Text>
              </Stack>
            </ModalBody>
          </ModalContent>
        </Modal>

        {/* First Section - Partnership and Impact */}
        <Box pt="80px" px={{ base: "20px", md: "0", lg: "0" }}>
          <Container maxW="860px">
            {/* Partnership Logo Section */}
            <Flex 
              direction="column" 
              align="center" 
              mb={12}
            >
              <Box 
                position="relative"
                bg="white"
                p={6}
                borderRadius="2xl"
                boxShadow="sm"
                mb={4}
              >
                <Flex align="center" gap={3}>
                  <img src={logo} style={{width: "120px", height: "80px"}} alt="Wavy logo" />
                </Flex>
                <Link 
                  href="https://impact.veritree.com/goodapi"
                  position="absolute"
                  top={2}
                  right={2}
                  p={2}
                  _hover={{ opacity: 0.8 }}
                >
                  <FaExternalLinkAlt />
                </Link>
              </Box>
            </Flex>

            {/* Main Content Section - Metric Cards */}
            <SimpleGrid columns={{ base: 1, md: 3 }} spacing={8} mb={12}>
              {/* Trees Planted Card */}
              <Box
                bg="white"
                borderRadius="2xl"
                overflow="hidden"
                position="relative"
                boxShadow="sm"
              >
                <Box
                  h="160px"
                  bgImage="https://storage.googleapis.com/treeshop-app-assets/Heyday/Cariboo%20Forest%201.jpeg"
                  bgSize="cover"
                  bgPosition="center"
                  position="relative"
                />
                <Box
                  position="absolute"
                  top="140px"
                  left={0}
                  right={0}
                  height="40px"
                  style={{
                    background: `url("data:image/svg+xml,%3Csvg viewBox='0 0 500 150' preserveAspectRatio='none' style='height: 100%25; width: 100%25;' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.00,49.98 C149.99,150.00 349.20,-49.98 500.00,49.98 L500.00,150.00 L0.00,150.00 Z' style='stroke: none; fill: white;'%3E%3C/path%3E%3C/svg%3E")`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat'
                  }}
                />
                <Box p={6} pt={8}>
                  <Stack spacing={1}>
                    <Text fontSize="sm" fontWeight="medium" color="gray.600">
                      Trees to be planted
                    </Text>
                    <Text fontSize="3xl" fontWeight="bold" color="gray.900">
                      {total_trees.toLocaleString()}
                    </Text>
                    <Flex justify="flex-start" align="center">
                      <Link 
                        href="https://impact.veritree.com/goodapi" 
                        isExternal
                        fontSize="xs"
                        color="gray.500"
                        _hover={{ 
                          color: "gray.700",
                          textDecoration: "none" 
                        }}
                      >
                        Learn More
                      </Link>
                    </Flex>
                  </Stack>
                </Box>
              </Box>

              {/* CO2 Sequestered Card */}
              <Box
                bg="white"
                borderRadius="2xl"
                overflow="hidden"
                position="relative"
                boxShadow="sm"
              >
                <Box
                  h="160px"
                  bgImage="https://storage.googleapis.com/treeshop-app-assets/Heyday/Cariboo%20Forest%202.jpeg"
                  bgSize="cover"
                  bgPosition="center"
                  position="relative"
                />
                <Box
                  position="absolute"
                  top="140px"
                  left={0}
                  right={0}
                  height="40px"
                  style={{
                    background: `url("data:image/svg+xml,%3Csvg viewBox='0 0 500 150' preserveAspectRatio='none' style='height: 100%25; width: 100%25;' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.00,49.98 C149.99,150.00 349.20,-49.98 500.00,49.98 L500.00,150.00 L0.00,150.00 Z' style='stroke: none; fill: white;'%3E%3C/path%3E%3C/svg%3E")`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat'
                  }}
                />
                <Box p={6} pt={8}>
                  <Stack spacing={1}>
                    <Text fontSize="sm" fontWeight="medium" color="gray.600">
                      Carbon Sequestered
                    </Text>
                    <Text fontSize="3xl" fontWeight="bold" color="gray.900">
                      {(total_trees * 0.31 * 0.8).toFixed(2)} tonnes
                    </Text>
                  
                  </Stack>
                </Box>
              </Box>

              {/* Hectares Reforested Card */}
              <Box
                bg="white"
                borderRadius="2xl"
                overflow="hidden"
                position="relative"
                boxShadow="sm"
              >
                <Box
                  h="160px"
                  bgImage="https://storage.googleapis.com/treeshop-app-assets/Heyday/Cariboo%20Forest%203.jpeg"
                  bgSize="cover"
                  bgPosition="center"
                  position="relative"
                />
                <Box
                  position="absolute"
                  top="140px"
                  left={0}
                  right={0}
                  height="40px"
                  style={{
                    background: `url("data:image/svg+xml,%3Csvg viewBox='0 0 500 150' preserveAspectRatio='none' style='height: 100%25; width: 100%25;' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.00,49.98 C149.99,150.00 349.20,-49.98 500.00,49.98 L500.00,150.00 L0.00,150.00 Z' style='stroke: none; fill: white;'%3E%3C/path%3E%3C/svg%3E")`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat'
                  }}
                />
                <Box p={6} pt={8}>
                  <Stack spacing={1}>
                    <Text fontSize="sm" fontWeight="medium" color="gray.600">
                      Hectares reforested
                    </Text>
                    <Text fontSize="3xl" fontWeight="bold" color="gray.900">
                      {(total_trees / 10000).toFixed(2)}
                    </Text>
                    <Text fontSize="sm" color="gray.500">
                      Based on 10,000 trees per hectare density
                    </Text>
                  </Stack>
                </Box>
              </Box>
            </SimpleGrid>
          </Container>
        </Box>

        {/* Contribute Section */}
        <Box py="80px" textAlign="center">
          <Box
            m="auto"
            p={{ base: "0", md: "0", lg: "0" }}
            textAlign="center"
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <Text fontSize="2xl" fontWeight="semibold" mb="6">
              Contribute to Our Forest
            </Text>
            
            <Flex direction={{ base: "column", md: "row" }} gap={8} align="start">
              <Box flex="1" p={6} bg="white" borderRadius="xl" boxShadow="sm" width={{ base: "100%", md: "50%" }}>
                <Stack spacing={6}>
                  <Text color="gray.700">
                    Select number of trees to plant:
                  </Text>
                  
                  <Stack spacing={4}>
                    <SimpleGrid columns={2} spacing={3}>
                      {[100, 500, 5000, 10000].map(trees => (
                        <TreeOption
                          key={trees}
                          trees={trees}
                          selected={!isCustom && selectedTrees === trees}
                          onClick={() => handleTreeSelection(trees)}
                        />
                      ))}
                    </SimpleGrid>

                    <CustomTreeInput
                      value={customTrees}
                      onChange={handleCustomTreesChange}
                      selected={isCustom}
                      onClick={handleCustomSelection}
                    />
                  </Stack>

                  <Box bg="green.50" p={4} borderRadius="md">
                    <Flex justify="space-between" align="center">
                      <Stack spacing={0}>
                        <Text fontWeight="medium">Make it monthly</Text>
                        <Text fontSize="sm" color="gray.600">Save more trees every month</Text>
                      </Stack>
                      <Switch 
                        id="recurring"
                        colorScheme="green"
                        isChecked={isRecurring}
                        onChange={(e) => setIsRecurring(e.target.checked)}
                      />
                    </Flex>
                  </Box>

                  <Button
                    colorScheme="green"
                    size="lg"
                    onClick={handleContribute}
                    py={7}
                  >
                    Plant {selectedTrees} Trees {isRecurring ? 'Monthly' : 'Now'} for ${selectedTrees}
                  </Button>
                </Stack>
              </Box>

              <Box flex="1" p={6} bg="white" borderRadius="xl" boxShadow="sm" width={{ base: "100%", md: "50%" }}>
                <Text fontSize="xl" fontWeight="medium" mb="6">
                  Your Impact with {selectedTrees} Trees
                </Text>
                
                <Stack spacing={6}>
                  {/* Carbon Sequestered Impact */}
                  <Box 
                    p={4} 
                    borderRadius="lg"
                    bg="green.50"
                    _hover={{ transform: 'translateY(-2px)', transition: 'transform 0.2s' }}
                  >
                    <Flex gap={4} align="start">
                      <Box
                        p={3}
                        borderRadius="full"
                        bg="green.100"
                      >
                        <Text fontSize="xl" role="img" aria-label="carbon sequestered">🌱</Text>
                      </Box>
                      <Stack spacing={1} flex="1">
                        <Text fontWeight="bold" color="green.800">Carbon Sequestered</Text>
                        <Text fontSize="2xl" fontWeight="bold" color="green.600">
                          {(selectedTrees * 0.31 * 0.8).toFixed(2)} tonnes
                        </Text>
                        <Text color="gray.600" fontSize="sm">
                          of Carbon Dioxide will be sequesterd
                        </Text>
                      </Stack>
                    </Flex>
                  </Box>

                  {/* Wildlife Impact */}
                  <Box 
                    p={4} 
                    borderRadius="lg"
                    bg="blue.50"
                    _hover={{ transform: 'translateY(-2px)', transition: 'transform 0.2s' }}
                  >
                    <Flex gap={4} align="start">
                      <Box
                        p={3}
                        borderRadius="full"
                        bg="blue.100"
                      >
                        <Text fontSize="xl" role="img" aria-label="wildlife">🦋</Text>
                      </Box>
                      <Stack spacing={1} flex="1">
                        <Text fontWeight="bold" color="blue.800">Wildlife Habitat</Text>
                        <Text fontSize="2xl" fontWeight="bold" color="blue.600">
                          {(selectedTrees * 0.01).toFixed(1)}m²
                        </Text>
                        <Text color="gray.600" fontSize="sm">
                          of new habitat created for local species
                        </Text>
                      </Stack>
                    </Flex>
                  </Box>

                  {/* Water Resources Impact */}
                  <Box 
                    p={4} 
                    borderRadius="lg"
                    bg="cyan.50"
                    _hover={{ transform: 'translateY(-2px)', transition: 'transform 0.2s' }}
                  >
                    <Flex gap={4} align="start">
                      <Box
                        p={3}
                        borderRadius="full"
                        bg="cyan.100"
                      >
                        <Text fontSize="xl" role="img" aria-label="water">💧</Text>
                      </Box>
                      <Stack spacing={1} flex="1">
                        <Text fontWeight="bold" color="cyan.800">Water Resources</Text>
                        <Text fontSize="2xl" fontWeight="bold" color="cyan.600">
                          {(selectedTrees * 2000).toLocaleString()}L
                        </Text>
                        <Text color="gray.600" fontSize="sm">
                          of water filtered annually
                        </Text>
                      </Stack>
                    </Flex>
                  </Box>

                  {/* Biodiversity Impact */}
                  <Box 
                    p={4} 
                    borderRadius="lg"
                    bg="purple.50"
                    _hover={{ transform: 'translateY(-2px)', transition: 'transform 0.2s' }}
                  >
                    <Flex gap={4} align="start">
                      <Box
                        p={3}
                        borderRadius="full"
                        bg="purple.100"
                      >
                        <Text fontSize="xl" role="img" aria-label="biodiversity">🌍</Text>
                      </Box>
                      <Stack spacing={1} flex="1">
                        <Text fontWeight="bold" color="purple.800">Biodiversity</Text>
                        <Text fontSize="2xl" fontWeight="bold" color="purple.600">
                          {Math.ceil(selectedTrees / 100)} species
                        </Text>
                        <Text color="gray.600" fontSize="sm">
                          supported through increased biodiversity
                        </Text>
                      </Stack>
                    </Flex>
                  </Box>
                </Stack>
              </Box>
            </Flex>
          </Box>
        </Box>
        <Box py="80px" bgColor="white">
          <Box
            m="auto"
            maxWidth="860px"
            align="center"
            p={{ base: "20px", md: "0", lg: "0" }}
          >
            <Text fontSize="3xl" fontWeight="bold" mb="40px">
              {second_title}
            </Text>

            <SimpleGrid
              columns={{ base: "1", md: "3", lg: "3" }}
              spacing={{ base: "0px", md: "20px", lg: "20px" }}
            >
              <Box
                p="8"
                borderRadius="24"
                mb="20px"
                bgImage={images[0]}
                bgSize="cover"
                bgRepeat="no-repeat"
                bgPosition="center"
                color="white"
                height="350px"
              >
              
              </Box>
              <Box
                p="8"
                borderRadius="24"
                mb="20px"
                bgImage={images[1]}
                bgSize="cover"
                bgRepeat="no-repeat"
                bgPosition="center"
                color="white"
                height="350px"
              >
             
              </Box>
              <Box
                p="8"
                borderRadius="24"
                mb="20px"
                bgImage={images[2]}
                bgSize="cover"
                bgRepeat="no-repeat"
                bgPosition="center"
                color="white"
                height="350px"
              >
               
              </Box>
            </SimpleGrid>
            <Text fontSize="xl" fontWeight="light">
             
              {second_description}
            </Text>
          </Box>
        </Box>
        <Box py="80px" bgColor="#04BD9C" color="white">
          <Box
            m="auto"
            maxWidth="860px"
            align="center"
            p={{ base: "20px", md: "0", lg: "0" }}
          >
            <Box width="90px" height="90px" mb="20px">
              <img src="https://uploads-ssl.webflow.com/6129929c4bf93b549649bfa5/646bc8ab214cf13962675386_did-you-know.png" />
            </Box>
            <Text fontSize="med" fontWeight="bold" mb="20px">
             DID YOU KNOW
            </Text>

            <Text fontSize="3xl" fontWeight="light" mb="20px">
              {did_you_know_copy}
            </Text>
          </Box>
        </Box>
        <Box py="40px" bgColor="#3A4148" color="white">
          <Box
            m="auto"
            maxWidth="860px"
            align="center"
            p={{ base: "20px", md: "0", lg: "0" }}
          >
            <Flex>
              <Box mr="80px" align="left">
                <Text fontSize="med" fontWeight="light" align="left">
                 
                      Our tree planting mission is fulfilled by our partnership with <b>Veritree</b>. 
                   
                  
                </Text>
              </Box>
              <Box></Box>
              <Link href="https://www.veritree.com/" isExternal>
                <Button colorScheme="teal" px="30px">
                  Join now
                </Button>
              </Link>
            </Flex>
          </Box>
        </Box>
      </Container>

      );
}