import React from 'react';
import heydayLoader from '../services/heyday/heydayloader';
import { Box, Flex, Container, SimpleGrid, Text, Button, Link } from '@chakra-ui/react';
import { FaExternalLinkAlt } from 'react-icons/fa';


export default function HeyDayView({id}) {
    const [data, setData] = React.useState(null)
    const [state, setState] = React.useState(null)
    const [gridColumns, setGridColumns] = React.useState(1)
    React.useEffect(() => {
       var d =  heydayLoader(id)
       setData(d)
    }, [id])

    if (!data) {
        return (
            <div>
                <h1>Loading...</h1>
            </div>
        )
    }
//https://www.madebyheyday.com/ 
    const {logo, description,total_trees_planted_copy, total_trees, did_you_know_copy,  title, banner_background, second_title, images, second_description} = data
    
    return (
      <Container minWidth="100%" p="0" bgColor="white">
      <Box pt="80px" bgColor="#EDF0F4" px={{ base: "20px", md: "0", lg: "0" }}>
        <Box m="auto" maxWidth="860px" align="center">

        <Flex direction="row" justify="center">
           
            <img src={logo} width="100%" height="100%" style={{maxWidth: "150px", maxHeight: "150px"}} />
            <a style={{"text-decoration": "none", "padding-left":"0%"}} href="https://www.madebyheyday.com/"><FaExternalLinkAlt /></a>

        </Flex>
        <br/>
          <Text fontSize="xl" fontWeight="light">
            {description}
          </Text>
        </Box>
      </Box>
      <Box py="80px" bgColor="#EDF0F4">
        <Box
          m="auto"
          maxWidth="860px"
          align="center"
          p={{ base: "20px", md: "0", lg: "0" }}
        >
          <Text fontSize="3xl" fontWeight="bold" mb="40px">
             {title}
          </Text>

          <SimpleGrid
            columns={{ base: gridColumns, md: gridColumns, lg: gridColumns }}
            spacing={{ base: "0px", md: "20px", lg: "20px" }}
          >
              <Box
                p={{ base: "4", md: "8", lg: "8" }}
                borderRadius="24"
                mb="20px"
                bgImage={banner_background}
                bgSize="cover"
                bgRepeat="no-repeat"
                bgPosition="center"
                color="white"
              >
                <Box
                  mb={{ base: "120px", md: "140px", lg: "140px" }}
                  align="left"
                >
                  <Text fontSize="5xl" fontWeight="medium">{total_trees}</Text>
                  <Text fontSize="2xl" fontWeight="medium">
                    {total_trees_planted_copy}
                    </Text>
                </Box>
              </Box>
            
                     
          </SimpleGrid>
        
        </Box>
      </Box>
      <Box py="80px" bgColor="white">
        <Box
          m="auto"
          maxWidth="860px"
          align="center"
          p={{ base: "20px", md: "0", lg: "0" }}
        >
          <Text fontSize="3xl" fontWeight="bold" mb="40px">
            {second_title}
          </Text>

          <SimpleGrid
            columns={{ base: "1", md: "3", lg: "3" }}
            spacing={{ base: "0px", md: "20px", lg: "20px" }}
          >
            <Box
              p="8"
              borderRadius="24"
              mb="20px"
              bgImage={images[0]}
              bgSize="cover"
              bgRepeat="no-repeat"
              bgPosition="center"
              color="white"
              height="350px"
            >
            
            </Box>
            <Box
              p="8"
              borderRadius="24"
              mb="20px"
              bgImage={images[1]}
              bgSize="cover"
              bgRepeat="no-repeat"
              bgPosition="center"
              color="white"
              height="350px"
            >
           
            </Box>
            <Box
              p="8"
              borderRadius="24"
              mb="20px"
              bgImage={images[2]}
              bgSize="cover"
              bgRepeat="no-repeat"
              bgPosition="center"
              color="white"
              height="350px"
            >
             
            </Box>
          </SimpleGrid>
          <Text fontSize="xl" fontWeight="light">
           
            {second_description}
          </Text>
        </Box>
      </Box>
      <Box py="80px" bgColor="#04BD9C" color="white">
        <Box
          m="auto"
          maxWidth="860px"
          align="center"
          p={{ base: "20px", md: "0", lg: "0" }}
        >
          <Box width="90px" height="90px" mb="20px">
            <img src="https://uploads-ssl.webflow.com/6129929c4bf93b549649bfa5/646bc8ab214cf13962675386_did-you-know.png" />
          </Box>
          <Text fontSize="med" fontWeight="bold" mb="20px">
           DID YOU KNOW
          </Text>

          <Text fontSize="3xl" fontWeight="light" mb="20px">
            {did_you_know_copy}
          </Text>
        </Box>
      </Box>
      <Box py="40px" bgColor="#3A4148" color="white">
        <Box
          m="auto"
          maxWidth="860px"
          align="center"
          p={{ base: "20px", md: "0", lg: "0" }}
        >
          <Flex>
            <Box mr="80px" align="left">
              <Text fontSize="med" fontWeight="light" align="left">
               
                    Our tree planting mission is fulfilled by our partnership with <b>theGoodAPI</b>
                 
                
              </Text>
            </Box>
            <Box></Box>
            <Link href="https://thegoodapi.com" isExternal>
              <Button colorScheme="teal" px="30px">
                Join now
              </Button>
            </Link>
          </Flex>
        </Box>
      </Box>
    </Container>

    );
}